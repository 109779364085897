import { useDisplay } from 'vuetify';
import { computed } from 'vue';

export function useMobile() {
  const { mobile } = useDisplay();
  const isMobile = computed(() => mobile.value);

  return {
    isMobile,
  };
}
