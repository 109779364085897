<template>
  <v-avatar
    v-if="initialsExist"
    :size="size"
    :color="backgroundColor"
    :style="borderStyle"
    :start="left"
  >
    <v-img
      v-if="loaded && userImageUrl"
      :src="userImageUrl"
      :alt="user.email"
      @load="onLoaded"
      @error="onError"
    />
    <span
      v-else
      :class="`tw-text-${fontColor} tw-font-medium`"
      :style="initialsStyle"
      >{{ initials }}</span
    >
  </v-avatar>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { camelCase, mapKeys } from 'lodash';

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  color: {
    type: String,
    default: 'orange',
  },
  size: {
    type: Number,
    default: 36,
  },
  left: {
    type: Boolean,
    default: false,
  },
});

const loaded = ref(false);
const backgroundColor = ref('');
const borderColor = ref('transparent');
const fontColor = ref('white');
const userImageUrl = ref(null);

const initials = computed(() => {
  const camelizedUser = mapKeys(props.user, (_v, k) => camelCase(k));
  const { firstName, lastName, email } = camelizedUser;
  let initials = '';

  if (firstName && lastName) {
    initials = firstName[0] + lastName[0];
  } else if (firstName) {
    initials = firstName[0];
  } else if (lastName) {
    initials = lastName[0];
  } else if (email) {
    // If end user doesn't have a first name or a last name, use first character of email
    initials = email[0];
  } else {
    return '';
  }

  // Remove non-alphanumeric and whitespace characters from initials. If left with an empty string, use first character of email
  const regex = /[^A-Za-z0-9]/g;
  initials = initials.replace(regex, '');

  if (initials.length === 0) initials = email[0];

  return initials.toUpperCase();
});

const initialsStyle = computed(() => {
  const fontSize = props.size * 0.5;
  return `font-size: ${fontSize}px;`;
});

const initialsExist = computed(() => initials.value.length > 0);

const borderStyle = computed(() => {
  if (borderColor.value === 'transparent') return '';
  return `border-width: 1px !important; border-color: ${borderColor.value} !important;`;
});

watch(
  () => props.user,
  (newUser, oldUser) => {
    if (newUser.avatarUrlWithGravatarUrlDefault !== oldUser.avatarUrlWithGravatarUrlDefault) {
      setUserImageUrl();
    }
  },
  { deep: true }
);

onMounted(() => {
  setColors();
  setUserImageUrl();
});

function onError() {
  loaded.value = false;
}

function onLoaded() {
  loaded.value = true;
}

function setColors() {
  // Set avatar colors for deleted users and techs
  if (props.user.name === '[deleted user]' || props.user.name === '[deleted tech]') {
    backgroundColor.value = 'white';
    fontColor.value = 'earl-400';
    borderColor.value = '#989a9c'; // earl-400
  } else {
    backgroundColor.value = props.color;
  }
}

function setUserImageUrl() {
  const avatarURL = props.user.avatarUrlWithGravatarUrlDefault;

  // In development we turn off gravatar to prevent console 404 errors.
  // Instead we load any images stored under User.avatar_url and fall back to initials.
  if (
    process.env.NODE_ENV === 'development' &&
    typeof avatarURL === 'string' &&
    avatarURL.length > 0 &&
    avatarURL.startsWith('https://secure.gravatar.com/avatar')
  ) {
    loaded.value = false;
  } else {
    userImageUrl.value = avatarURL;
    loaded.value = true;
  }
}
</script>
