<template>
  <v-snackbar
    v-model="snackbarProps.display"
    :color="snackbarProps.type || 'success'"
    rounded="true"
    transition="slide-y-transition"
    class="tw-elevation-24 tw-mt-16 print:tw-hidden"
    :timeout="snackbarProps.timeout"
    location="top"
    :min-width="minWidth"
  >
    <slot>
      <v-card-text class="tw-ml-2 tw-flex tw-items-center tw-gap-2 tw-p-0">
        <v-icon v-if="icons[snackbarProps.type]">{{ icons[snackbarProps.type] }} </v-icon>
        <ul
          v-if="hasMultipeMessages"
          class="chd-list tw-ml-4"
        >
          <li
            v-for="(item, index) in message"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
        <div
          v-else
          class="tw-font-weight-medium"
        >
          {{ displayMessage }}
        </div>
      </v-card-text>
    </slot>

    <template #actions>
      <v-btn
        color=""
        icon="mdi-close"
        :ripple="false"
        aria-label="close"
        @click="close"
      />
    </template>
  </v-snackbar>
</template>

<script setup>
import { capitalize, isPlainObject, isString } from 'lodash';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useMobile } from '@/src/common/composables/useMobile';

const store = useStore();
const { isMobile } = useMobile();

const snackbarProps = computed(() => store.state.snackbarProps);
const minWidth = computed(() => {
  return isMobile.value ? null : 'auto';
});

const icons = {
  success: 'mdi-check-circle-outline',
  error: 'mdi-alert-outline',
};

const message = computed(() => {
  const { errors } = snackbarProps.value.message;
  return errors ? errors : snackbarProps.value.message;
});

const hasMultipeMessages = computed(() => {
  const msg = message.value;

  if (isPlainObject(msg)) {
    const keys = Object.keys(msg);
    return keys.length > 1 || (Array.isArray(msg[keys[0]]) && msg[keys[0]].length > 1);
  }

  return Array.isArray(msg) && msg.length > 1;
});

const objectMessages = computed(() => {
  const msg = message.value;
  let messages = [];
  Object.keys(msg).forEach((key) => {
    messages = messages.concat(
      Array.isArray(msg[key]) && msg[key].map((m) => `${capitalize(key)} ${m}`)
    );
  });

  return messages;
});

const displayMessage = computed(() => {
  const msg = message.value;

  if (isString(msg) && !msg.includes('<html')) {
    return msg;
  } else if (Array.isArray(msg)) {
    return msg[0];
  } else if (isPlainObject(msg)) {
    return objectMessages.value[0];
  } else {
    return 'Oops! something went wrong, please try again';
  }
});

const close = () => {
  store.dispatch('showSnackbar', { display: false });
};
</script>

<style>
.chd-list,
.chd-list li {
  list-style: disc outside none;
}
.v-snackbar__content {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
</style>
