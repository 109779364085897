const SnackbarStore = {
  state: {
    snackbarProps: {
      display: false,
      message: [],
      type: 'info',
      timeout: 5000,
    },
  },

  mutations: {
    UPDATE_SNACKBAR(state, snackbarProps) {
      Object.assign(state.snackbarProps, snackbarProps);
    },
  },

  actions: {
    async showSnackbar(context, snackbarProps) {
      context.commit('UPDATE_SNACKBAR', snackbarProps);
    },
  },
};

export default Object.freeze(SnackbarStore);
